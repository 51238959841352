.container {
  display: grid;
  grid-template-columns: 1fr 248px;
  grid-column-gap: 4px;
  overflow: hidden;
  border: 1px solid #c9d1e5;
  border-radius: 8px;
}

.header {
  padding-bottom: 4px;
}

.content {
  margin: 24px;
}

.description {
  margin-bottom: 12px;
}

.phones {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: var(--decorative-theme-dark);
}

.sign {
  margin-top: 4px;

  &:empty {
    display: none;
  }
}

.more-info-image-wrapper {
  display: flex;
  align-self: flex-end;
  width: 248px;
  height: 140px;
}
